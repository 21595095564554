import $ from "jquery";
import Swiper from "swiper/bundle";
import "../../node_modules/isotope-layout/dist/isotope.pkgd.min";

// Hash
function getHashFilter() {
  var hash = location.hash;
  // get filter=filterName
  var matches = location.hash.match(/categorie=([^&]+)/i);
  var hashFilter = matches && matches[1];
  return hashFilter && decodeURIComponent(hashFilter);
}

jQuery(function ($) {
  const slideHero = new Swiper(".hero__slider", {
    loop: false,
    slidesPerView: 1,
    autoplay: {
      delay: 4000,
    },
    navigation: {
      nextEl: ".swiper-nav-next",
      prevEl: ".swiper-nav-prev",
    },
  });

  const slideFeatures = new Swiper(".features__slider", {
    loop: false,
    effect: "fade",
  });

  slideHero.controller.control = slideFeatures;
  slideFeatures.controller.control = slideHero;

  var $grid = $(".cars-list");

  // bind filter button click
  var $filters = $(".filter-button-group").on("click", "li", function () {
    var filterAttr = $(this).attr("data-filter");
    $filters.find(".active").removeClass("active");
    $(this).addClass("active");
    // set filter in hash
    location.hash = "categorie=" + encodeURIComponent(filterAttr);
  });

  var isIsotopeInit = false;

  function onHashChange() {
    var hashFilter = getHashFilter() || ".suv";
    if (!hashFilter && isIsotopeInit) {
      return;
    }
    isIsotopeInit = true;

    $grid.isotope({
      itemSelector: ".cars-list__item",
      layoutMode: "fitRows",
      filter: hashFilter,
      percentPosition: true,
      transformsEnabled: false,
    });

    if (hashFilter) {
      $filters.find("active").removeClass("active");
      $filters.find('[data-filter="' + hashFilter + '"]').addClass("active");
    }
  }

  $(window).on("hashchange", onHashChange);

  onHashChange();

  // View more
  $(".cars-list__view-more").on("click", function () {
    var idToggle = $(this).data("toggle");
    $(this).toggleClass("more-rotate");
    $("#cars-list__more-" + idToggle).slideToggle(function () {
      $grid.isotope("layout");
    });
    $(".active-more-" + idToggle).toggleClass("hide-actions");
  });

  // Popup "Offer"
  $(".car-offer").on("click", function (e) {
    e.preventDefault();
    var id = $(this).data("id");
    var productName = $(this).data("name");
    $("#car-offer-" + id).addClass("open-offer");
    $("#car-offer-" + id)
      .find("#selected-product-name")
      .val(productName);
  });

  $(".cars-offer__close").on("click", function () {
    $(".cars-offer").removeClass("open-offer");
    $(".cars-offer__back").hide();
    $(".cars-offer__front").show();
  });

  document.addEventListener("wpcf7mailsent", function ($cf7) {
    $(".cars-offer__front").hide();
    $(".cars-offer__back").show();
  });

  // FAQ Tabs
  $(".faq__title").on("click", function () {
    $(this).parents(".faq__item").toggleClass("active").find(".faq__info").slideToggle();
    $(this).parents(".faq__item").siblings().removeClass("active").find(".faq__info").slideUp();
  });

  // Tooltip link
  $("#tooltip").on("click", function (e) {
    e.preventDefault();
    var hash = this.hash;
    if ($(hash).length) {
      $("html, body").animate(
        {
          scrollTop: $(hash).offset().top - 50,
        },
        1000,
        function () {
          $(".faq__item:eq(2)").addClass("active").find(".faq__info").css("display", "block");
        }
      );
    }
  });
});
